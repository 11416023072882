<template>
  <div
    class="w-full group h-full flex items-center bg-white justify-center flex-col p-4 rounded-xl"
    :class="[{ 'cursor-pointer shadow-lg hover:shadow-2xl': !noLink, shadow: noLink }, customClass]"
  >
    <div v-if="$slots.header" class="flex flex-col items-center justify-center mb-4" :class="{ 'w-full': fullWHeader }">
      <slot name="header"></slot>
    </div>
    <fw-heading v-if="title" size="h4" class="text-center">
      <v-clamp v-if="!noClamp" autoresize :max-lines="1">
        {{ title }}
      </v-clamp>
      <div v-else>
        {{ title }}
      </div>
    </fw-heading>
    <div v-if="$slots.main" class="flex flex-col flex-1 items-center justify-center my-2">
      <slot name="main"></slot>
    </div>
    <div v-if="$slots.footer" class="text-xs flex items-center justify-between gap-3 text-gray-500">
      <slot name="footer"></slot>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    title: {
      type: String,
      default: '',
    },
    customClass: {
      type: String,
      default: '',
    },
    noClamp: {
      type: Boolean,
      default: false,
    },
    noLink: {
      type: Boolean,
      default: false,
    },
    fullWHeader: {
      type: Boolean,
      default: false,
    },
  },
}
</script>
