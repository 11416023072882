<template>
  <div class="listLoader">
    <div v-if="list">
      <div
        v-for="n in items"
        :key="'loading_row_' + n"
        class="px-2 py-1.5 my-1 rounded-lg border border-gray-100 flex bg-white"
      >
        <div
          class="animate-pulse h-6 rounded bg-gray-200"
          :class="{ 'w-4/6': n % 3 === 2, 'w-1/2': n % 3 === 1, 'w-2/6': n % 3 === 0 }"
        ></div>
      </div>
    </div>
    <div v-else class="grid grid-cols-2 md:grid-cols-3 lg:grid-cols-6 gap-4 mb-10 py-2">
      <div
        v-for="n in items"
        :key="'loading_card_' + n"
        class="animate-pulse w-full shadow group h-full flex items-center bg-gray-200 justify-center flex-col p-4 rounded-xl"
      >
        <div style="height: 130px;"></div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'LoadingPlaceholder',
  props: {
    size: {
      type: String,
      default: 'md',
    },
    list: {
      type: Boolean,
      default: true,
    },
  },
  computed: {
    items() {
      if (this.size === 'lg') {
        return 10
      } else if (this.size === 'sm') {
        return 2
      } else {
        return 5
      }
    },
  },
}
</script>

<style scoped></style>
