<template>
  <fw-layout mobile-ready :back-to-enable="false" :main-sidebar="false" :footer="true">
    <template #header-nav>
      <div class="inline-block">
        <BlockHeaderSwitchManage :has-permissions="showHeaderSwitchManage" />
      </div>
    </template>
    <template #main-content>
      <PanelUCIdHero :user="user" class="mt-4 mb-10 mx-5" version="v2" />
      <PanelDashboard class="mx-5" />
    </template>
  </fw-layout>
</template>

<script>
import PanelUCIdHero from '@/fw-modules/fw-core-vue/id/components/panels/PanelUCIdHero'
import BlockHeaderSwitchManage from '@/fw-modules/fw-core-vue/ui/components/blocks/BlockHeaderSwitchManage'
import PanelDashboard from '@/components/panels/PanelDashboard'

export default {
  components: {
    BlockHeaderSwitchManage,
    PanelUCIdHero,
    PanelDashboard,
  },

  data() {
    return {
      loading: false,
      loadingStats: false,
      stats: [],
    }
  },

  computed: {
    api() {
      return this.$store.state.api.base
    },

    user() {
      return this.$store.getters.getUser
    },

    userPermissions() {
      return this.$store.getters.userPermissions
    },

    showHeaderSwitchManage() {
      return this.userPermissions.isAnyManager
    },
  },

  mounted() {
    this.getStats()
  },

  methods: {
    async getStats() {
      this.loadingStats = true

      const statsData = await this.api.getStats()
      if (statsData) {
        this.stats = [
          {
            label: 'Toneios inscritos da edição atual',
            value: parseInt(statsData.edition_tournaments),
            valueSuffix: null,
          },
          {
            label: 'Modalidades inscritas da edição atual',
            value: parseInt(statsData.edition_tournaments),
            valueSuffix: null,
          },
          {
            label: 'Total modalidades inscritas',
            value: parseInt(statsData.total_sports),
            valueSuffix: null,
          },
          {
            label: 'Total torneios inscritos',
            value: parseInt(statsData.total_tournaments),
            valueSuffix: null,
          },
        ]
      }
      this.loadingStats = false
    },
  },
}
</script>
