<template>
  <div
    class="fw-message font-medium"
    :class="[{ 'text-center': centered, 'rounded-xl': rounded, 'p-4': padding }, type]"
  >
    <slot></slot>
  </div>
</template>

<script>
export default {
  name: 'FwMessage',
  props: {
    type: {
      type: String,
      default: 'info', // info, success, warning, error
    },
    padding: {
      type: Boolean,
      default: true,
    },
    rounded: {
      type: Boolean,
      default: true,
    },
    centered: {
      type: Boolean,
      default: false,
    },
  },
}
</script>
<style lang="scss" scoped>
.fw-message.info {
  background-color: #394a851f;
  color: #394a85;
}
.fw-message.warning {
  background-color: #d98f211d;
  color: #857039;
}
.fw-message.error {
  background-color: #d92d211d;
  color: #853f39;
}
</style>
