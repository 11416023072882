<template>
  <div
    class="panel-uc-id-hero md:bg-gray-200 md:bg-opacity-50 md:p-4 md:rounded-xl items-center justify-center md:flex gap-2"
  >
    <div class="px-10 py-5">
      <BlockUCId :user="user || localUser" :intro="version == 'v1'" />
    </div>
    <div class="flex flex-1 flex-col gap-2 justify-between">
      <div v-if="version == 'v2'" class="flex justify-center md:justify-start">
        <h2 class="text-2xl font-semibold text-center opacity-90">
          <v-clamp autoresize :max-lines="2">{{ $t('hello') }}, {{ user.name }}.</v-clamp>
        </h2>
      </div>
      <fw-heading
        v-if="!statsLoading && statsTitle"
        size="h3"
        class="hidden md:block md:opacity-40 text-center md:text-left"
        >{{ statsTitle }}</fw-heading
      >
      <slot v-if="stats && stats.length" name="stats">
        <PanelStats
          class="hidden md:inline-flex"
          :stats="stats"
          :stats-warning="statsWarning"
          :loading="statsLoading"
          :hide-if-empty="statsHideIfEmpty"
        />
      </slot>
    </div>
  </div>
</template>

<script>
import BlockUCId from '@/fw-modules/fw-core-vue/id/components/blocks/BlockUCIdQRCode'
import PanelStats from '@/fw-modules/fw-core-vue/ui/components/panels/PanelStats'

export default {
  components: {
    BlockUCId,
    PanelStats,
  },

  props: {
    user: {
      type: Object,
    },
    stats: {
      type: Array,
      deep: true,
    },
    statsWarning: {
      type: Boolean,
      default: true,
    },
    statsLoading: {
      type: Boolean,
      default: false,
    },
    statsTitle: {
      type: String,
      default: null,
    },
    statsHideIfEmpty: {
      type: Boolean,
      default: false,
    },
    version: {
      type: String,
      default: 'v1',
    },
  },

  computed: {
    localUser() {
      return this.$store.getters.getUser
    },
  },
}
</script>

<i18n>
{
  "pt": {
    "hello": "Olá"
  },
  "en": {
    "hello": "Hello"
  }
}
</i18n>
