import dayjs from 'dayjs'
import customParseFormat from 'dayjs/plugin/customParseFormat'
import isoWeek from 'dayjs/plugin/isoWeek'
import isToday from 'dayjs/plugin/isToday'
import isTomorrow from 'dayjs/plugin/isTomorrow'
import isYesterday from 'dayjs/plugin/isYesterday'
import timezone from 'dayjs/plugin/timezone'
import utc from 'dayjs/plugin/utc'
import weekday from 'dayjs/plugin/weekday'

dayjs.extend(customParseFormat)
dayjs.extend(weekday)
dayjs.extend(utc)
dayjs.extend(timezone)
dayjs.extend(isToday)
dayjs.extend(isTomorrow)
dayjs.extend(isYesterday)
dayjs.extend(isoWeek)
dayjs.tz.setDefault('Europe/Lisbon')

// Fix toJSON dates timezone
Date.prototype.toJSON = function() {
  return dayjs(this).format()
}

const Dates = {
  getDatesFromRange(startDate, endDate) {
    var dates = [],
      currentDate = new Date(startDate),
      addDays = function(days) {
        var date = new Date(this.valueOf())
        date.setDate(date.getDate() + days)
        return date
      }
    while (currentDate <= new Date(endDate)) {
      dates.push(currentDate)
      currentDate = addDays.call(currentDate, 1)
    }
    return dates
  },

  now() {
    return dayjs().tz('Europe/Lisbon')
  },
  buildCore(value) {
    if (dayjs(value).isUTC()) {
      return dayjs.utc(value)
    }
    return dayjs(value)
  },
  build(value) {
    return dayjs.tz(String(value), 'Europe/Lisbon')
  },
  formatToAPI(value) {
    return this.buildCore(value).format()
  },
  formatDateToAPI(value) {
    return this.buildCore(value).format('YYYY-MM-DD')
  },
  formatTimeToAPI(value) {
    return this.buildCore(value).format('HH:mm:ss')
  },
  format(value, format = 'DD/MM/YYYY HH:mm') {
    return this.build(value).format(format)
  },
  formatCore(value, format = 'DD/MM/YYYY HH:mm') {
    return this.buildCore(value).format(format)
  },
  from(value, format) {
    return dayjs(value, format)
  },
  nowDiff(value, type) {
    return this.build(value).diff(this.now(), type)
  },
  toDate(value) {
    return this.build(value).toDate()
  },
  toJSON(value) {
    return this.buildCore(value).toJSON()
  },
  toDateString(value, format = 'YYYY-MM-DD') {
    if (Object.prototype.toString.call(value) !== '[object Date]') {
      value = new Date(value)
    }
    const month = String(value.getMonth() + 1).padStart(2, '0')
    const day = String(value.getDate()).padStart(2, '0')

    const dateAsString = `${value.getFullYear()}-${month}-${day}`

    return this.formatCore(dateAsString, format)
  },
  getCurrentWeekStartDay() {
    const date = Dates.now().startOf('week')
    if (date.format('ddd').toLowerCase() == 'sun') {
      return date.add(1, 'days')
    } else {
      return date
    }
  },

  secondsToTime(seconds) {
    let date = new Date(0)
    date.setSeconds(seconds)
    return date.toISOString().substr(11, 8)
  },
  isToday(value) {
    return this.build(value).isToday()
  },
  isYesterday(value) {
    return this.build(value).isYesterday()
  },
  isTomorrow(value) {
    return this.build(value).isTomorrow()
  },

  convertMillisecondsToDuration(milliseconds) {
    // https://bobbyhadz.com/blog/javascript-convert-milliseconds-to-hours-minutes-seconds
    function padTo2Digits(num) {
      return num.toString().padStart(2, '0')
    }

    let seconds = Math.floor(milliseconds / 1000)
    let minutes = Math.floor(seconds / 60)
    let hours = Math.floor(minutes / 60)

    seconds = seconds % 60
    minutes = minutes % 60

    // 👇️ If you don't want to roll hours over, e.g. 24 to 00
    // 👇️ comment (or remove) the line below
    // commenting next line gets you `24:00:00` instead of `00:00:00`
    // or `36:15:31` instead of `12:15:31`, etc.
    // hours = hours % 24

    return `${padTo2Digits(hours)}:${padTo2Digits(minutes)}:${padTo2Digits(seconds)}`
  },
}

export default Dates
