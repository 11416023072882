import FwEnvConfig from '@/fw-modules/fw-core-vue/config'
import Api from '@/fw-modules/fw-core-vue/api/Api'
import store from '@/store'
import axios from 'axios'

export default {
  base(config) {
    if (!config) config = {}
    config.baseURL = FwEnvConfig.apiUrlIduc
    return Api(config)
  },

  login(email, password, keepSignIn, callbackApp = null) {
    const config = { ignoreDataLog: true, ignoreResponseLog: true }
    return this.base(config).post('/v1/login', {
      email: email,
      password: password,
      longLivedToken: keepSignIn,
      callbackApp: callbackApp,
    })
  },

  async signupExternalAccount(email, password, name, application) {
    let result = null

    result = await this.base().post('/v1/register', {
      email: email,
      name: name,
      password: password,
      application: application,
    })
    return result?.data
  },

  async signupExternalAccountWithToken(token, password, name, application) {
    let result = null

    result = await this.base().post('/v1/register', {
      token: token,
      name: name,
      password: password,
      application: application,
    })
    return result?.data
  },

  async changePswExternalAccount(token, password, application) {
    let result = null

    result = await this.base().post('/v1/recover/password', {
      token: token,
      password: password,
      application: application,
    })
    return result?.data
  },

  async recoverExternalAccount(email, application) {
    let result = null

    result = await this.base().post('/v1/recover/password', {
      email: email,
      application: application,
    })
    return result?.data
  },

  async resendConfirmationEmail(email, application) {
    let result = null

    result = await this.base().post('/v1/register/resend', {
      email: email,
      application: application,
    })

    return result?.data
  },

  async activateAccount(token) {
    let result = null

    result = await this.base().post('/v1/activate', {
      token: token,
    })

    return result?.data
  },

  async logout() {
    try {
      await this.base().delete('/v1/logout')
    } catch (error) {
      console.error('Failed to logout', error)
    }
    return true
  },

  async getSelfUser(withSupportedLanguages = false) {
    let data = null
    if (withSupportedLanguages) data = { params: { supported_languages: true } }

    const response = await this.base().get('/v1/user', data)
    return response.data
  },
  async updateSelfUser(data) {
    const response = await this.base().post('/v1/user', data)
    return response.data
  },
  async updateUserPhoto(fileKey) {
    const response = await this.base().post('/v1/user/photo', { key: fileKey })
    return response.data
  },
  async deleteUserPhoto() {
    const response = await this.base().delete('/v1/user/photo')
    return response.data
  },
  async getApps() {
    const response = await this.base().get('/v1/user/apps')
    return response.data
  },
  async getAppUrl(application, context = null) {
    let data = null
    if (context) data = { params: context }

    const response = await this.base().get(`/v1/user/app/${application}/url`, data)
    return response.data
  },
  async appAuth(application) {
    if (!application) {
      return false
    }

    const response = await this.getAppUrl(application)
    if (response.token) {
      window.location.href = response.url
    }
    return !!response.token
  },
  async appAuthLogin(token) {
    if (token) {
      const response = await this.base().get(`/v1/user/app/${process.env.VUE_APP_KEY}/token/${token}`)
      store.commit('setNewLogin', response.data)
    }
  },

  async getQRCodeString() {
    const response = await this.base().get('/v1/user/qrcode/code')
    return response.data.code
  },

  async checkToken(token) {
    const params = { token: token, application: process.env.VUE_APP_KEY }
    const response = await this.base().get('/v1/check/token', { params: params })
    return response.data
  },

  async getTokens() {
    const response = await this.base().get('/v1/user/tokens', { params: { group: true } })
    return response.data
  },

  async expireTokens(tokenKeys) {
    const response = await this.base().delete('/v1/user/tokens', { data: { keys: tokenKeys } })
    return response.data
  },

  async passwordlessStart(rsa) {
    const response = await this.base().post('/v1/passwordless/login', { rsa_public: rsa })
    return response.data
  },

  async requestAmaURL() {
    const response = await this.base().get('/v1/auth/ama')
    return response.data
  },

  /* 
  sendAmaToken, payload:
  "token_type": "Bearer",
  "expires_in": 7200,
  "access_token": "8f396841-739f-455a-b801-a2af45880f7c",
  "refresh_token": "eyJhbGciOiJSUzI1NiIsImtpZCI6IjgwRDU4Njg3NTM0NDg1N0RCMjlCN0VBRDYzRDUwRkIxQ0I3NjdERjQiLCJ4NXQiOiJnTldHaDFORWhYMnltMzZ0WTlVUHNjdDJmZlEiLCJ0eXAiOiJKV1QifQ.eyJyZWZyZXNoX3Rva2VuIjoiYmVlMTViZDgtNTc0MC00YzRhLWE1OGMtMzk0Y2IyZGJiMTdlIn0.DHJAFBv6dh1_Wk-er-i5m_0gcNizJ2FzsNx_8hvdVwW_yJGE5tJhilGLDecE2UCtJ6jswOlym--XO2ZBEd7C867egKRXXgTi1HeMW44wg4NWVRGighOfsf5Xn87xcXkQB55wvlAjm2ihhoeVb9WP5YJYafrluKp01Zdl13JnXeEsEmHDVIZuqSKO247CUqLFfKpZIWFDLmnmlcdkZctRfLEOu8arepnd_XQGI5VPf2Rb1AkuxY6MOOuZZ32I9NNCf-PoQJvSzUzva9cZ1boIdIZZQUcGn4acDsQBkgzcySm1AYviWj2ONQYZ0Up6uczP9vt1774jkwSOw65FSxMHNw",
  "state": "ucfw"
  */
  async sendAmaToken(payload) {
    const response = await this.base().post('/v1/auth/ama', payload)
    return response.data
  },

  async getAmaData(url) {
    const response = await axios.get(url, {
      headers: {
        Host: 'preprod.autenticacao.gov.pt',
        'Content-Length': '0',
        'Content-Type': 'application/json',
      },
      timeout: 60000,
    })
    return response.data
  },

  helpers: {
    domainSuggestions: [
      'ucframework.pt',
      'sapo.pt',
      'portugalmail.pt',
      'clix.pt',
      'kanguru.pt',
      'mail.pt',
      'mail.optimus.pt',
      'oniduo.pt',
      'gmail.com',
      'yahoo.com',
      'hotmail.com',
      'aol.com',
      'hotmail.co.uk',
      'hotmail.fr',
      'msn.com',
      'yahoo.fr',
      'wanadoo.fr',
      'orange.fr',
      'comcast.net',
      'yahoo.co.uk',
      'yahoo.com.br',
      'yahoo.co.in',
      'live.com',
      'rediffmail.com',
      'free.fr',
      'gmx.de',
      'web.de',
      'yandex.ru',
      'ymail.com',
      'libero.it',
      'outlook.com',
      'uol.com.br',
      'bol.com.br',
      'mail.ru',
      'cox.net',
      'hotmail.it',
      'sbcglobal.net',
      'sfr.fr',
      'live.fr',
      'verizon.net',
      'live.co.uk',
      'googlemail.com',
      'yahoo.es',
      'ig.com.br',
      'live.nl',
      'bigpond.com',
      'terra.com.br',
      'yahoo.it',
      'neuf.fr',
      'yahoo.de',
      'alice.it',
      'rocketmail.com',
      'att.net',
      'laposte.net',
      'facebook.com',
      'bellsouth.net',
      'yahoo.in',
      'hotmail.es',
      'charter.net',
      'yahoo.ca',
      'yahoo.com.au',
      'rambler.ru',
      'hotmail.de',
      'tiscali.it',
      'shaw.ca',
      'yahoo.co.jp',
      'sky.com',
      'earthlink.net',
      'optonline.net',
      'freenet.de',
      't-online.de',
      'aliceadsl.fr',
      'virgilio.it',
      'home.nl',
      'qq.com',
      'telenet.be',
      'me.com',
      'yahoo.com.ar',
      'tiscali.co.uk',
      'yahoo.com.mx',
      'voila.fr',
      'gmx.net',
      'mail.com',
      'planet.nl',
      'tin.it',
      'live.it',
      'ntlworld.com',
      'arcor.de',
      'yahoo.co.id',
      'frontiernet.net',
      'hetnet.nl',
      'live.com.au',
      'yahoo.com.sg',
      'zonnet.nl',
      'club-internet.fr',
      'juno.com',
      'optusnet.com.au',
      'blueyonder.co.uk',
      'bluewin.ch',
      'skynet.be',
      'sympatico.ca',
      'windstream.net',
      'mac.com',
      'centurytel.net',
      'chello.nl',
      'live.ca',
      'aim.com',
      'bigpond.net.au',
      'icloud.com',
    ],
  },
}
