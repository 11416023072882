var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"fw-editor"},[(_vm.editor)?_c('bubble-menu',{staticClass:"bubble-menu",attrs:{"tippy-options":{ duration: 100 },"editor":_vm.editor}},[_c('button',{class:{ 'is-active': _vm.editor.isActive('bold') },on:{"click":function($event){_vm.editor
          .chain()
          .focus()
          .toggleBold()
          .run()}}},[_vm._v(" Negrito ")]),_c('button',{class:{ 'is-active': _vm.editor.isActive('italic') },on:{"click":function($event){_vm.editor
          .chain()
          .focus()
          .toggleItalic()
          .run()}}},[_vm._v(" Itálico ")]),_c('button',{class:{ 'is-active': _vm.editor.isActive('strike') },on:{"click":function($event){_vm.editor
          .chain()
          .focus()
          .toggleStrike()
          .run()}}},[_vm._v(" Riscado ")])]):_vm._e(),_c('editor-content',{attrs:{"editor":_vm.editor}}),(_vm.showLimit)?_c('div',{staticClass:"character-count text-xs text-gray-400 py-1"},[_vm._v(" "+_vm._s(_vm.editor.storage.characterCount.characters())+"/"+_vm._s(_vm.maxCharacters)+" caracteres ("+_vm._s(_vm.editor.storage.characterCount.words())+" palavras) ")]):_vm._e()],1)
}
var staticRenderFns = []

export { render, staticRenderFns }