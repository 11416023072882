import Api from '@/fw-modules/fw-core-vue/api/Api'
import FwEnvConfig from '@/fw-modules/fw-core-vue/config'

export default {
  base(config) {
    let api = Api(config)
    api.defaults.baseURL = FwEnvConfig.apiUrlCompetitions
    return api
  },
  async getHomeDashboard() {
    const response = await this.base().get(`/api/v1/dashboard`)
    return response.data
  },
  async getLeagueDetails(editionKey, leagueKey) {
    const response = await this.base().get(`/api/v1/editions/${editionKey}/leagues/${leagueKey}`)
    return response.data
  },
  async getTeams(params) {
    const response = await this.base().get(`/api/v1/teams`, { params: params })
    return response.data
  },

  /*
   * Get tournaments
   * @param {Object} params: with_editions, with_teams, with_sports, with_leagues, with_org_units
   */
  async getTournaments(params) {
    const response = await this.base().get(`/api/v1/tournaments`, { params: params })
    return response.data
  },

  async cancelTournament(tournamentKey) {
    const response = await this.base().delete(`/api/v1/tournaments/${tournamentKey}`)
    return response.data
  },

  /*
   * Get tournament
   */
  async getTournament(tournamentKey, params) {
    const response = await this.base().get(`/api/v1/tournaments/${tournamentKey}`, { params: params })
    return response.data
  },

  async getTournamentStages(tournamentKey) {
    const response = await this.base().get(`/api/v1/tournaments/${tournamentKey}/stages`)
    return response.data
  },

  /*
   * Get single team by key
   */
  async getTeam(teamKey, params) {
    const response = await this.base().get(`/api/v1/teams/${teamKey}`, { params: params })
    return response.data
  },

  /*
   * Create team in a tournament
   * @param {Object} payload:
   * name: String
   * logo_key: FileKey
   * color: String
   * players_emails: Array of emails
   * leader: Userkey
   */
  async createTeam(tournamentKey, payload) {
    const response = await this.base().post(`/api/v1/tournaments/${tournamentKey}/teams`, payload)
    return response.data
  },

  async updateTeam(teamKey, payload) {
    const response = await this.base().put(`/api/v1/teams/${teamKey}`, payload)
    return response.data
  },

  async updateTeamStatus(teamKey, status) {
    // "draft_2" or "submitted"
    const response = await this.base().post(`/api/v1/teams/${teamKey}`, {
      status,
    })
    return response.data
  },

  /*
   * Cancel team sign up
   */
  async cancelTeamSignUp(teamKey) {
    const response = await this.base().delete(`/api/v1/teams/${teamKey}`)
    return response.data
  },

  /*
   * Get tournament team groups
   */
  async getTournamentTeamGroups(tournamentKey, params) {
    const response = await this.base().get(`/api/v1/tournaments/${tournamentKey}/teamgroups`, { params: params })
    return response.data
  },

  /*
   * Get team groups
   * @param {Object} params: with_org_units, with_users, sort=name, direction=asc|desc
   */
  async getTeamGroups(params) {
    const response = await this.base().get(`/api/v1/teamgroups`, { params: params })
    return response.data
  },

  /*
   * Resend invite
   */
  async resendTeamInvite(inviteKey) {
    const response = await this.base().post(`/api/v1/invites/${inviteKey}/resend`, {
      confirm: true,
    })
    return response.data
  },

  /**
   * Send teams message
   * @param {string} teamKey
   * @param {string} message
   * @param {Array} files
   * @param {Boolean} is_private
   * @returns
   */
  async sendTeamMessages(teamKey, message = '', files = []) {
    const response = await this.base().post(`/api/v1/teams/${teamKey}/messages`, {
      description: message,
      files: files,
    })
    return response.data
  },

  /**
   * Send global message
   * @param {Object} payload
   * {
   *     "team_state": [
   *         "submitted"
   *     ],
   *     "gender": [
   *         "M"
   *     ],
   *     "sport_key": [],
   *     "tournament_stage": [
   *         "intragroups"
   *     ],
   *     "team_group": "",
   *     "description": "Esta é uma mensagem <b>global</b>!",
   *     "confirm": false
   * }
   */
  async sendGlobalMessages(editionKey, leagueKey, payload) {
    const response = await this.base().post(
      `/api/v1/manage/editions/${editionKey}/leagues/${leagueKey}/messages`,
      payload
    )
    return response.data
  },

  async getGlobalMessages(editionKey, leagueKey) {
    const response = await this.base().get(`/api/v1/manage/editions/${editionKey}/leagues/${leagueKey}/messages`)
    return response.data
  },

  /**
   * Get team messages
   * @param {string} teamKey
   * @returns
   */
  async getTeamMessages(teamKey) {
    const response = await this.base().get(`/api/v1/teams/${teamKey}/messages`)
    return response.data
  },

  /*
   * Accept team invitation
   */
  async acceptTeamInvitation(teamKey, invitationKey, accept) {
    const response = await this.base().post(`/api/v1/teams/${teamKey}/invite/${invitationKey}`, {
      confirm: accept,
    })
    return response.data
  },

  /*
   * Get tournament teams
   * @param {Object} params: with_users, with_logos
   */
  async getTournamentTeams(tournamentKey, params) {
    const response = await this.base().get(`/api/v1/tournaments/${tournamentKey}/teams`, { params: params })
    return response.data
  },

  /*
   * Save user personal information for an edition
   */
  async createSignupEdition(editionKey, payload) {
    const response = await this.base().post(`/api/v1/editions/${editionKey}/signups`, payload)
    return response.data
  },

  async updateSignupEdition(editionKey, signupKey, payload) {
    const response = await this.base().put(`/api/v1/editions/${editionKey}/signups/${signupKey}`, payload)
    return response.data
  },

  /*
   * Get last edition user data
   */
  async getPrefilledData(editionKey) {
    const response = await this.base().get(`/api/v1/editions/${editionKey}/signups`)
    return response.data
  },

  /*
   * Get organic units
   */
  async getOrganicUnits() {
    const response = await this.base().get(`/api/v1/organic-units`)
    return response.data
  },

  /*
   * Get sports
   * @param {Object} params: sort (title_en), direction (asc, desc)
   */
  async getSports(params) {
    const response = await this.base().get(`/api/v1/sports`, { params: params })
    return response.data
  },

  /*
   * Get my teams - gets the users teams
   */
  async getMyTeams() {
    const response = await this.base().get(`/api/v1/teams`, {
      params: {
        with_invites: true,
      },
    })
    return response.data
  },

  /*
   * Get my stats
   */
  async getStats() {
    const response = await this.base().get(`/api/v1/stats`)
    return response.data
  },

  /*
   * Get user info
   */
  async getUserInfo() {
    const response = await this.base().get(`/api/v1/user`)
    return response.data
  },

  /* MANAGEMENT */

  async managementGetEditions() {
    const response = await this.base().get(`/api/v1/manage/editions`)
    return response.data
  },

  async managementGetEdition(editionKey) {
    const response = await this.base().get(`/api/v1/manage/editions/${editionKey}`)
    return response.data
  },

  async managementUpdateEdition(editionKey, payload) {
    const response = await this.base().put(`/api/v1/manage/editions/${editionKey}`, payload)
    return response.data
  },

  async managementGetEditionLeagues(editionKey) {
    const response = await this.base().get(`/api/v1/manage/editions/${editionKey}/leagues`)
    return response.data
  },

  async managementGetEditionLeague(editionKey, leagueKey) {
    const response = await this.base().get(`/api/v1/manage/editions/${editionKey}/leagues/${leagueKey}`)
    return response.data
  },

  async managementUpdateEditionLeague(editionKey, leagueKey, payload) {
    const response = await this.base().put(`/api/v1/manage/editions/${editionKey}/leagues/${leagueKey}`, payload)
    return response.data
  },

  async getGlobalActivityLogs(payload) {
    // PAYLOAD ===
    // "service": "competitions",
    // "page": 1,
    // "start_date": "2022-01-01",
    // "end_date": "2022-12-31",
    // "reference_keys": {
    // },
    // "code": "",
    // "context": "",
    // "context_key": "",
    // "by_user_key": "",
    // "by_token_key": ""
    const response = await this.base().get('/api/v1/activity', { params: payload })
    return response.data
  },

  async getActivityLogs(params) {
    if (params.tournamentKey) return await this.getTournamentActivity(params.tournamentKey, params)
    return await this.getGlobalActivityLogs(params)
  },

  async getTournamentActivity(tournamentKey, params) {
    const response = await this.base().get(`/api/v1/manage/tournaments/${tournamentKey}/activity`, {
      params: params,
    })
    return response.data
  },

  async getTeamActivity(teamKey, params) {
    const response = await this.base().get(`/api/v1/manage/teams/${teamKey}/activity`, {
      params: params,
    })
    return response.data
  },

  /*
   * Get notifications
   * @param {Object} params: item_type (tournament), users (true/false)
   */
  async getNotifications(params) {
    if (params.tournamentKey) return await this.getTournamentNotifications(params.tournamentKey, params)

    const response = await this.base().get(`/api/v1/notifications`, { params: params })
    return response.data
  },

  /*
   * Get notification detail
   * @param {Object} params: item_type (tournament), users (true/false)
   */
  async getNotification(notificationKey) {
    const response = await this.base().get(`/api/v1/notifications/${notificationKey}`)
    return response.data
  },

  async getTournamentNotifications(tournamentKey, params) {
    const response = await this.base().get(`/api/v1/manage/tournaments/${tournamentKey}/notifications`, {
      params: params,
    })
    return response.data
  },

  async managementCreateTournaments(leagueKey, editionKey, tournaments) {
    const response = await this.base().post(`/api/v1/manage/tournaments/bulk`, {
      league_key: leagueKey,
      edition_key: editionKey,
      tournaments,
    })
    return response.data
  },

  // /*
  //  * Get tournaments data
  //  */
  // async getTournamentsData(params) {
  //   const response = await this.base().get(`/api/v1/manage/tournaments_data`, { params: params })
  //   return response.data
  // },

  async managementGetTournament(tournamentKey) {
    const response = await this.base().get(`/api/v1/manage/tournaments/${tournamentKey}`)
    return response.data
  },

  async managementGetTournamentStages(tournamentKey) {
    const response = await this.base().get(`/api/v1/manage/tournaments/${tournamentKey}/stages`)
    return response.data
  },

  async managementGetTournaments(params) {
    const response = await this.base().get(`/api/v1/manage/tournaments`, {
      params: params,
    })
    return response.data
  },

  async managementUpdateTournament(tournamentKey, payload) {
    const response = await this.base().put(`/api/v1/manage/tournaments/${tournamentKey}`, payload)
    return response.data
  },

  async managementUpdateSignupTournament(tournamentKey, isSignupOpen) {
    const response = await this.base().post(`/api/v1/manage/tournaments/${tournamentKey}`, {
      signup_open: isSignupOpen,
    })
    return response.data
  },

  async managementGetTeams(editionKey, leagueKey, tournamentKey = null, params) {
    let path = `/api/v1/manage/editions/${editionKey}/leagues/${leagueKey}/teams`
    if (tournamentKey)
      path = `/api/v1/manage/editions/${editionKey}/leagues/${leagueKey}/tournaments/${tournamentKey}/teams`
    const response = await this.base().get(path, {
      params: params,
    })
    return response.data
  },

  async managementGetTeam(teamKey) {
    const response = await this.base().get(`/api/v1/manage/teams/${teamKey}`)
    return response.data
  },

  async managementUpdateTeam(teamKey, payload) {
    const response = await this.base().put(`/api/v1/manage/teams/${teamKey}`, payload)
    return response.data
  },

  async managementGetTeamActivity(teamKey) {
    const response = await this.base().get(`/api/v1/manage/teams/${teamKey}/activity`)
    return response.data
  },

  async managementUpdateTeamStatus(teamKey, status, description) {
    const response = await this.base().post(`/api/v1/manage/teams/${teamKey}`, {
      status: status,
      description: description,
    })
    return response.data
  },

  async managementUpdateTeamStage(teamKey, stageKey) {
    const response = await this.base().post(`/api/v1/manage/teams/${teamKey}`, {
      tournament_stage: stageKey,
    })
    return response.data
  },

  async managementGetUserDetails(teamKey, playerKey) {
    const response = await this.base().get(`/api/v1/manage/teams/${teamKey}/players/${playerKey}`)
    return response.data
  },

  async managementGetTeamMessages(teamKey) {
    const response = await this.base().get(`/api/v1/manage/teams/${teamKey}/messages`)
    return response.data
  },

  /**
   * Send teams message
   * @param {string} teamKey
   * @param {string} message
   * @param {Array} files
   * @param {Boolean} is_private
   * @returns
   */
  async managementSendTeamMessages(teamKey, message = '', files = [], is_private = false) {
    const response = await this.base().post(`/api/v1/manage/teams/${teamKey}/messages`, {
      description: message,
      files: files,
      is_private: is_private,
    })
    return response.data
  },

  async managementUpdateTeamMessage(teamKey, messageKey, message = '', files = [], is_private = false) {
    const response = await this.base().put(`/api/v1/manage/teams/${teamKey}/messages/${messageKey}`, {
      description: message,
      files: files,
      is_private: is_private,
    })
    return response.data
  },

  async managementDeleteTeamMessage(teamKey, messageKey) {
    const response = await this.base().delete(`/api/v1/manage/teams/${teamKey}/messages/${messageKey}`)
    return response.data
  },

  /**
   *
   * @param {String} editionKey
   * @param {{ "leagues": { leagueKey: [ userKey ] } }} payload
   */
  async updateEditionPermissions(editionKey, payload) {
    const response = await this.base().post(`/api/v1/manage/editions/${editionKey}/permissions`, payload)
    return response.data
  },

  async deleteEditionPermissions(editionKey, payload) {
    const response = await this.base().delete(`/api/v1/manage/editions/${editionKey}/permissions`, {
      data: payload,
    })
    return response.data
  },

  async getEditionPermissions(editionKey, params) {
    const response = await this.base().get(`/api/v1/manage/editions/${editionKey}/permissions`, {
      params: params,
    })
    return response.data
  },

  async searchUsersForEditionPermissions(editionKey, params) {
    const response = await this.base().get(`/api/v1/manage/editions/${editionKey}/permissions/search`, {
      params: params,
    })
    return response.data
  },

  /**
   *
   * @param {String} tournamentKey
   * @param {{ "leagues": { leagueKey: [ userKey ] } }} payload
   */
  async managementMoveTeams(tournamentKey, payload) {
    const response = await this.base().post(`/api/v1/manage/tournaments/${tournamentKey}/teams/move`, payload)
    return response.data
  },

  async managementEditTournamentStage(tournamentKey, tournamentStageKey, payload) {
    const response = await this.base().put(
      `/api/v1/manage/tournaments/${tournamentKey}/stages/${tournamentStageKey}`,
      payload
    )
    return response.data
  },

  /**
   *
   * @param {String} tournamentKey
   * @param {String} tournamentStageKey
   * @param {{ "create_byes": true, "confirm": true }} payload
   *
   * If create_byes is true, "fanthom" teams will be created when the total number
   * is not a power of two, a number of the form 2n, meaning 2 multiplied by itself n times;
   */
  async managementStartTournament(tournamentKey, tournamentStageKey, payload) {
    const response = await this.base().post(
      `/api/v1/manage/tournaments/${tournamentKey}/stages/${tournamentStageKey}/sync/start`,
      payload
    )
    return response.data
  },

  /**
   *
   * @param {String} tournamentKey
   * @param {String} tournamentStageKey
   * @param {{ "confirm": true }} payload
   */
  async managementEndTournament(tournamentKey, tournamentStageKey, payload) {
    const response = await this.base().post(
      `/api/v1/manage/tournaments/${tournamentKey}/stages/${tournamentStageKey}/sync/end`,
      payload
    )
    return response.data
  },
}
