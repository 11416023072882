import CoreMutations from '@/fw-modules/fw-core-vue/store/mutations'

export default Object.assign(CoreMutations, {
  setSavingData(state, data) {
    state.isSaving = data
  },
  setUserPermissions(state, data) {
    state.session.permissions = data
  },
  setUserPermissionsLoaded(state, loaded = false) {
    state.session.permissionsLoaded = loaded
  },
  saveCurrentScrollPos(state, data) {
    state.tmbScrollPos[data.key] = data.pos
  },
  deleteCurrentScrollPos(state, data) {
    delete state.tmbScrollPos[data]
  },
})
