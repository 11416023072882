import FwEnvConfig from '@/fw-modules/fw-core-vue/config'
import querystring from 'querystring'
import Api from '@/fw-modules/fw-core-vue/api/Api'
import store from '@/store'

// import utils from '../../utilities/utils'
// import LZWCompress from '../../utilities/compress'

// const MB = 1024 * 1024
// const MAX_STORAGE_SIZE = 4 * MB
// const MAX_LOG_BUFFER_SIZE = 2 * MB

// let TIMEZONE = null
// try {
//   TIMEZONE = Intl.DateTimeFormat().resolvedOptions().timeZone
// } catch {
//   TIMEZONE = String(new Date().getTimezoneOffset() / 60)
// }

// Logs service (disable for now)
// --------------------------------
// const LOGS = {
//   key: utils.randomLowerString(6),
//   encoder: LZWCompress.encoder(),
//   sending: false,
//   lastSent: null,
//   backoff: 5 * 60 * 1000,
//   checkPreviousLogs: true,
//   message: '',
//   add(upperType, obj) {
//     let withError = upperType === 'ERROR'
//     let message = `${new Date().toISOString()} ${upperType}`
//     for (const value of Object.values(obj)) {
//       const valueType = typeof value
//       if (valueType === 'string') {
//         message += ` - ${value}`
//       } else if (valueType !== 'object') {
//         message += ` - ${utils.JSONSafeStringify(value)}`
//       } else if (value instanceof Error) {
//         message += ` - ${value.toString()} \n${value.stack}\n\n`
//         withError = true
//       } else if (!(value instanceof Window)) {
//         message += ` - ${utils.JSONSafeStringify(value)}`
//       }
//     }
//     this.encoder.feed(message + '\n')

//     if (withError || this.encoder.size() > MAX_LOG_BUFFER_SIZE) {
//       ServiceStorage.sendLogsToBackend()
//     }
//   },
// }

// // Set device info as first log
// if (navigator) {
//   LOGS.encoder.feed(`${new Date().toISOString()} INFO-SYS
//     os:${navigator.platform} cpu:${navigator.hardwareConcurrency} ram:${navigator.deviceMemory}`)

//   let activeSpeaker = localStorage.getItem('pods.active.speaker')
//   let activeVideo = localStorage.getItem('pods.active.video')
//   let mirrorVideo = localStorage.getItem('device.video.mirror.disabled')
//   let unlimitedSS = localStorage.getItem('device.screenshare.hd.unlimited')
//   LOGS.encoder.feed(`
//     app:${process.env.VUE_APP_KEY} ${process.env.VUE_APP_VERSION}
//     pods max:${localStorage.getItem('pods.max')} activeSpeaker:${activeSpeaker} activeVideo:${activeVideo}
//     audio input:${localStorage.getItem('device.audio.input')} output:${localStorage.getItem('device.audio.output')}
//     video input:${localStorage.getItem('device.video.input')} mirror:${mirrorVideo}
//     video hd:${localStorage.getItem('device.video.hd')}:${localStorage.getItem('device.video.hd.unlimited')}
//     screenshare hd:${localStorage.getItem('device.video.hd')}:${unlimitedSS}`)

//   if (navigator.connection)
//     LOGS.encoder.feed(`
//     type:${navigator.connection.effectiveType}:${navigator.connection.type}
//     downlink:${navigator.connection.downlink}:${navigator.connection.downlinkMax} rtt:${navigator.connection.rtt}`)

//   LOGS.encoder.feed(`
//     timezone:${TIMEZONE}
//     language:${navigator.language}-${navigator.languages}`)

//   if (navigator.mediaDevices) {
//     LOGS.encoder.feed(`
//     media:${utils.JSONSafeStringify(navigator.mediaDevices.getSupportedConstraints())}`)
//   }

//   LOGS.encoder.feed(`
//     agent:${navigator.userAgent}\n`)
// }

// for (const type of ['log', 'trace', 'debug', 'info', 'warn', 'error']) {
//   const oldConsole = console[type]
//   const upperType = type.toUpperCase()
//   const withDebug = localStorage.getItem('debug')

//   if (withDebug) {
//     console[type] = async function() {
//       oldConsole.apply(console, arguments)
//       LOGS.add(upperType, arguments)
//     }
//   } else {
//     console[type] = async function() {
//       LOGS.add(upperType, arguments)
//     }
//   }
// }

// window.onerror = function errorLogs(message, url, lineNo, columnNo, error) {
//   console.error('Window error', message, url, lineNo, columnNo, error)
// }
// window.onunhandledrejection = function(error) {
//   console.error('Unhandled rejection', error.reason, error.promise, error)
// }

// window.onrejectionhandled = function(error) {
//   console.error('Rejection handled', error.reason, error.promise, error)
// }

// var iOS = ['iPad', 'iPhone', 'iPod'].indexOf(navigator.platform) >= 0
// var eventName = iOS ? 'pagehide' : 'beforeunload'
// var oldOnBeforeUnload = window[`on${eventName}`]
// window.addEventListener(eventName, function() {
//   if (LOGS.encoder.size()) {
//     let message = store.state.currentSessionKey + '-' + LOGS.encoder.end()

//     let counter = 0
//     const whileKey = true
//     while (whileKey) {
//       if (message.length > MAX_STORAGE_SIZE) {
//         localStorage.setItem(`logs.previous.${counter}`, message.substr(0, MAX_STORAGE_SIZE))
//         message = message.substr(MAX_STORAGE_SIZE, message.length)
//         counter += 1
//       } else {
//         localStorage.setItem(`logs.previous.${counter}`, message)
//         break
//       }
//     }
//   }

//   if (oldOnBeforeUnload && typeof oldOnBeforeUnload == 'function') oldOnBeforeUnload()
// })

const ServiceStorage = {
  base(config) {
    let api = Api(config)
    api.defaults.baseURL = FwEnvConfig.apiUrlStorage
    return api
  },

  // Disable for now
  sendLogsToBackend() {
    return
  },

  // async sendLogsToBackend(force) {
  //   if (LOGS.sending || (!force && new Date() - LOGS.lastSent <= LOGS.backoff)) {
  //     return
  //   }

  //   if (LOGS.checkPreviousLogs) {
  //     LOGS.checkPreviousLogs = false

  //     let counter = 0
  //     let previousMessage = ''
  //     const whileKey = true
  //     while (whileKey) {
  //       let lsMsg = localStorage.getItem(`logs.previous.${counter}`)
  //       if (!lsMsg) break

  //       previousMessage += lsMsg
  //       localStorage.removeItem(`logs.previous.${counter}`)
  //       counter += 1
  //     }

  //     if (previousMessage.length) {
  //       try {
  //         const key = previousMessage.split('-', 1)[0]
  //         await ServiceStorage.sendLogsToBackendCore(key, previousMessage.substr(key.length + 1))
  //       } catch (error) {
  //         console.error('Failed to send previous logs', error)
  //       }
  //     }
  //   }

  //   const withLock = !LOGS.sending
  //   if (withLock) LOGS.sending = true
  //   let message = ''

  //   try {
  //     if (LOGS.encoder.size()) {
  //       message = LOGS.encoder.end()
  //       await ServiceStorage.sendLogsToBackendCore(store.state.currentSessionKey, message)
  //     }
  //   } catch (error) {
  //     console.error('Failed to send logs', error)
  //     if (message.length) localStorage.setItem('logs.previous.0', message)
  //   } finally {
  //     LOGS.lastSent = new Date()
  //     if (withLock) LOGS.sending = false
  //   }
  // },
  // async sendLogsToBackendCore(key, message) {
  //   const activityApi = ServiceStorage.base({ ignoreError: true })

  //   const user = await store.getters.getUser
  //   const config = {
  //     params: {
  //       key: key,
  //       encode: 'lzw',
  //       application: process.env.VUE_APP_KEY,
  //       user_key: user && user.key ? user.key : '',
  //     },
  //     quietly: true,
  //   }
  //   const response = await activityApi.get('/v1/logs', config)
  //   if (!response || !response.data) return

  //   // Now send to S3
  //   const s3Api = Api({
  //     baseURL: response.data.host,
  //     ignoreError: true,
  //     ignoreDataLog: true,
  //     ignoreAppHeader: true,
  //     transformRequest: [
  //       (data, headers) => {
  //         delete headers.common.Authorization
  //         return data
  //       },
  //     ],
  //   })

  //   const s3Config = {
  //     quietly: true,
  //     headers: {
  //       'Content-Type': 'application/lzw',
  //     },
  //   }
  //   await s3Api.put(response.data.path, message, s3Config)
  // },

  async getFiles(fileKeys) {
    const response = await ServiceStorage.base().get('/v1/files', {
      params: {
        key: fileKeys,
      },
      paramsSerializer: params => {
        return querystring.stringify(params)
      },
    })
    return response.data
  },

  async putSmallFile(file, type, mime, name) {
    const response = await ServiceStorage.base().post('/v1/file/chunk', file, {
      params: {
        type: type,
        name: name,
        mime_type: mime,
      },
    })
    return response.data
  },

  async getZipFile(application, key, publicToken, filename) {
    const response = await ServiceStorage.base().get(`/z/${application}/${publicToken}/${key}/${filename}.zip`, {
      responseType: 'blob',
    })
    return response.data
  },

  async setFilesMetadata(files, setLabels = false) {
    const filesRef = {}
    const labelsKeys = []
    const missingToken = []

    for (let file of files) {
      if (!file.token) {
        missingToken.push(file)
      }
      if (setLabels && file.type == 'image') {
        filesRef[file.key] = file
        labelsKeys.push(file.key)
      }
    }

    if (missingToken.length) {
      const publicToken = await store.getters.getUser.getPublicToken()
      if (publicToken) {
        for (let file of missingToken) file.token = publicToken
      }
    }

    if (labelsKeys.length) {
      let filesMetadata = await ServiceStorage.getFiles(labelsKeys)
      for (let key in filesMetadata) {
        filesRef[key].labels = filesMetadata[key].labels
        filesRef[key].persons = filesMetadata[key].persons
      }
    }
  },

  getFileUrl(file, publicToken = null) {
    return file.url_format
      .replace('{TOKEN}', publicToken || file.token)
      .replace('{KEY}', file.key)
      .replace('{FILENAME}', file.filename)
  },

  getFileViewUrl(file, download = false) {
    return `${ServiceStorage.getFileUrl(file)}?dl=${download ? 1 : 0}`
  },

  getImageUrl(file, size = null, publicToken = null) {
    return file.thumb_url_format
      .replace('{TOKEN}', publicToken || file.token)
      .replace('{KEY}', file.key)
      .replace('{FILENAME}', file.filename)
      .replace('{SIZE}', size || 'max2k')
  },

  getImageViewUrl(file, size = null, publicToken = null) {
    return `${ServiceStorage.getImageUrl(file, size, publicToken)}?dl=0`
  },

  getPublicFileUrl(file) {
    return file.url_format.replace('{KEY}', file.key).replace('{FILENAME}', file.filename)
  },

  getPublicImageUrl(file, size) {
    return file.thumb_url_format
      .replace('{KEY}', file.key)
      .replace('{FILENAME}', file.filename)
      .replace('{SIZE}', size || 'max2k')
  },

  getUserImageUrl(user, size) {
    if (user && (user.photo || user.icon)) {
      return ServiceStorage.getPublicImageUrl(user.photo || user.icon, size || 'small')
    }
  },

  getUserImageViewUrl(user, size) {
    const url = ServiceStorage.getUserImageUrl(user, size)
    if (url) return `${url}?dl=0`
  },
}

export default ServiceStorage
