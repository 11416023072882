<template>
  <div class="block-account-update">
    <div class="flex gap-5 items-center mb-5">
      <div>
        <Uploader
          reference-id="avatar"
          allowed="images"
          bucket-type="image"
          :is-docked="true"
          :is-custom="true"
          :clear-after="true"
          :limit="1"
          :path-to-upload="photoUploaderUrl"
          @upload="updateAvatar"
        >
          <div slot="navbar">
            <Avatar :user="user" size="lg" />
            <a href="#" class="text-xs text-primary my-2">{{ $t('edit') }}</a>
          </div>
        </Uploader>
      </div>
      <div class="flex-1 flex gap-1 flex-col">
        <fw-heading size="h2">{{ user.name || user.full_name }}</fw-heading>
        <div class="flex items-center">
          <svg
            class="fill-current w-5 h-5 mr-2"
            xmlns="http://www.w3.org/2000/svg"
            viewBox="0 0 24 24"
            width="24"
            height="24"
          >
            <path fill="none" d="M0 0h24v24H0z" />
            <path
              d="M16 4a1 1 0 0 1 1 1v4.2l5.213-3.65a.5.5 0 0 1 .787.41v12.08a.5.5 0 0 1-.787.41L17 14.8V19a1 1 0 0 1-1 1H2a1 1 0 0 1-1-1V5a1 1 0 0 1 1-1h14zm-1 2H3v12h12V6zM7.4 8.829a.4.4 0 0 1 .215.062l4.355 2.772a.4.4 0 0 1 0 .674L7.615 15.11A.4.4 0 0 1 7 14.77V9.23c0-.221.18-.4.4-.4zM21 8.84l-4 2.8v.718l4 2.8V8.84z"
            />
          </svg>
          <svg
            class="fill-current w-5 h-5"
            xmlns="http://www.w3.org/2000/svg"
            viewBox="0 0 24 24"
            width="24"
            height="24"
          >
            <path fill="none" d="M0 0h24v24H0z" />
            <path
              d="M6.455 19L2 22.5V4a1 1 0 0 1 1-1h18a1 1 0 0 1 1 1v14a1 1 0 0 1-1 1H6.455zm-.692-2H20V5H4v13.385L5.763 17zM7 10h2a3 3 0 0 0 6 0h2a5 5 0 0 1-10 0z"
            />
          </svg>
          <span v-if="user.meeting" class="ml-2 text-sm">@{{ user.meeting.key_alias }}</span>
        </div>
        <fw-button v-if="user.photo" size="xs" type="link" @click.native="deletePhoto">{{
          $t('removePhoto')
        }}</fw-button>
      </div>
    </div>

    <fw-panel :title="$t('personalInstitutionalData')" section custom-class="flex flex-col gap-5">
      <div>
        <fw-label>{{ $t('displayName') }}</fw-label>
        <form v-if="editMode" class="flex justify-between" @submit.prevent="doSave" @keyup.enter="doSave">
          <b-field
            :type="{ 'is-danger': $v.user.name.$error }"
            :message="$v.user.name.$error ? $t('displayNameRequired') : ''"
            class="mb-0 flex-1 mr-5"
          >
            <b-input v-model="user.name" type="text" name="name" />
          </b-field>
          <div>
            <fw-button type="regular" :loading="loading" @click.native="doSave">{{ $t('save') }}</fw-button>
          </div>
        </form>
        <div v-else>
          <div class="flex justify-between items-center border-b border-gray-200 pb-2">
            <span class="mr-2">{{ user.name || user.full_name }}</span>
            <a class="text-primary text-sm" @click="editMode = true">{{ $t('edit') }}</a>
          </div>
        </div>
      </div>
      <div>
        <fw-label>{{ $t('fullName') }}</fw-label>
        <div class="text-sm text-gray-500">{{ user.full_name }}</div>
      </div>
      <div v-if="user.number">
        <fw-label>{{ $t('institutionalNumber') }}</fw-label>
        <div class="text-sm text-gray-500">{{ user.number }}</div>
      </div>
      <div>
        <fw-label>Email</fw-label>
        <div class="text-sm text-gray-500">{{ user.email }}</div>
      </div>
      <slot name="extra-options"></slot>
    </fw-panel>

    <fw-heading class="mt-10 my-5">{{ $t('preferences') }}</fw-heading>

    <fw-panel title="Interface e notificações" custom-class="flex flex-col gap-1">
      <fw-label>{{ $t('language') }}</fw-label>
      <div>
        <b-dropdown v-model="selectedLanguage" aria-role="list" position="is-bottom-right">
          <fw-button-dropdown slot="trigger" type="light" :label="languageDescription[selectedLanguage]" />
          <b-dropdown-item value="pt" aria-role="listitem">
            <span>Português</span>
          </b-dropdown-item>
          <b-dropdown-item value="en" aria-role="listitem">
            <span>English</span>
          </b-dropdown-item>
        </b-dropdown>
        <fw-tip>{{ $t('languageInfo') }}</fw-tip>
      </div>
    </fw-panel>

    <fw-heading class="mt-10 my-5">{{ $t('security') }}</fw-heading>

    <fw-panel :title="$t('activeSessions')" :subtitle="$t('byPlatform')" custom-class="flex flex-col gap-5">
      <div v-for="(values, name) in tokens" :key="name">
        <fw-label uppercase class="flex items-center">
          <span>{{ name }}</span>
          <fw-badge inline type="simple">{{ values.count }}</fw-badge>
        </fw-label>
        <div v-for="token in values.tokens" :key="token.key">
          <div class="flex gap-3 p-2 bg-opacity-30 hover:bg-opacity-50 bg-gray-200 rounded-lg my-2 items-center">
            <div class="flex-1 flex flex-col gap-1 text-sm">
              <div>
                <span>
                  {{
                    token.user_agent && token.user_agent.browser
                      ? `${token.user_agent.browser} - ${token.user_agent.os}`
                      : token.user_agent
                      ? token.user_agent.name
                      : 'N/A'
                  }}
                </span>
                <span
                  v-if="token.current_session"
                  class="font-medium bg-primary text-xs text-white py-0.5 px-1 rounded-lg"
                  >{{ $t('current') }}</span
                >
              </div>
              <div class="text-xs text-gray-500">
                {{ $t('lastActive') }}: <span>{{ token.last_used_date || token.created_date | formatDateTime }}</span>
                <span v-if="token.ips.length"> IP: {{ token.ips.join(', ') }} </span>
              </div>
            </div>
            <div>
              <fw-button type="light" @click.native="endToken(token)">
                <fw-icon-trash class="w-5 h-5"></fw-icon-trash>
              </fw-button>
            </div>
          </div>

          <div
            v-for="(children, childName) in token.children"
            :key="childName"
            style="margin-left:25px"
            class="flex-1 flex flex-col gap-1 text-sm"
          >
            <fw-label uppercase class="flex items-center">
              <span>{{ childName }}</span>
            </fw-label>
            <div v-for="child in children" :key="child.key">
              <div class="flex gap-3 p-2 bg-opacity-30 hover:bg-opacity-50 bg-gray-200 rounded-lg my-2 items-center">
                <div class="flex-1 flex flex-col gap-1 text-sm">
                  <div>
                    <span>
                      {{
                        child.user_agent && child.user_agent.browser
                          ? `${child.user_agent.browser} - ${child.user_agent.os}`
                          : child.user_agent
                          ? child.user_agent.name
                          : 'N/A'
                      }}
                    </span>
                    <span
                      v-if="child.current_session"
                      class="font-medium bg-primary text-xs text-white py-0.5 px-1 rounded-lg"
                      >{{ $t('current') }}</span
                    >
                  </div>
                  <div class="text-xs text-gray-500">
                    {{ $t('lastActive') }}:
                    <span>{{ child.last_used_date || child.created_date | formatDateTime }}</span>
                    <span v-if="child.ips.length"> IP: {{ child.ips.join(', ') }} </span>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </fw-panel>

    <fw-panel title="Ações" section custom-class="flex flex-col gap-5">
      <fw-button type="light" :expanded="isMobile" @click.native="$router.push({ name: 'logout' })">
        {{ $t('logout') }}
      </fw-button>
    </fw-panel>
  </div>
</template>

<script>
import { required, minLength } from 'vuelidate/lib/validators'
import FwEnvConfig from '@/fw-modules/fw-core-vue/config'
import Avatar from '@/fw-modules/fw-core-vue/ui/components/users/Avatar'
import ServiceAuth from '@/fw-modules/fw-core-vue/id/services/ServiceAuth'
import Uploader from '@/fw-modules/fw-core-vue/storage/components/Uploader'
import utils from '@/fw-modules/fw-core-vue/utilities/utils'

export default {
  components: {
    Avatar,
    Uploader,
  },

  data() {
    return {
      loading: false,
      editMode: false,
      photoUploaderUrl: FwEnvConfig.apiUrlStorage + '/v1/file',
      selectedLanguage: this.$store.state.language || this.$i18n.locale,
      languageDescription: {
        pt: 'Português',
        en: 'English',
      },
      tokens: [],
    }
  },

  validations: {
    user: {
      name: {
        required,
        min: minLength(3),
      },
    },
  },

  computed: {
    user() {
      return this.$store.getters.getUser
    },
    isMobile() {
      return utils.isMobile()
    },
  },

  watch: {
    selectedLanguage() {
      this.$store.dispatch('setLanguage', this.selectedLanguage)
      this.$i18n.locale = this.selectedLanguage
    },
  },

  mounted() {
    this.getTokens()
  },

  methods: {
    async doSave() {
      this.$v.$touch()
      if (!this.$v.$invalid && this.user) {
        this.loading = true
        try {
          const user = await ServiceAuth.updateSelfUser({ name: this.$v.user.name.$model || '' })
          this.$store.dispatch('setUser', { user: user })
          this.$buefy.snackbar.open({
            message: 'Os seus dados foram atualizados',
            type: 'is-primary',
            position: 'is-bottom-right',
            duration: 5000,
          })
        } catch (error) {
          console.error('Updating name', error)
          this.$buefy.snackbar.open({
            message: 'Não foi possível atualizar os seus dados',
            type: 'is-danger',
            position: 'is-bottom-right',
            duration: 5000,
          })
        }

        setTimeout(() => {
          this.loading = false
          this.editMode = false
        }, 250)
      }
    },

    async updateAvatar(uploadedFiles) {
      var file = uploadedFiles[0]
      const user = await ServiceAuth.updateUserPhoto(file.response.data.file.key)
      await this.$store.dispatch('setUser', { user: user })
    },

    async deletePhoto() {
      const user = await ServiceAuth.deleteUserPhoto()
      await this.$store.dispatch('removeUserPhoto')
      await this.$store.dispatch('setUser', { user: user })

      if (!user.photo) {
        this.$buefy.snackbar.open({
          message: 'A fotografia foi eliminada',
          type: 'is-primary',
          position: 'is-bottom-right',
          duration: 5000,
        })
      }
    },

    async getTokens() {
      this.loading = true
      try {
        const tokens = await ServiceAuth.getTokens()
        this.tokens = tokens
      } catch (error) {
        console.error(error)
      }

      this.loading = false
    },

    async endToken(token) {
      this.$buefy.dialog.confirm({
        title: this.$t('messageConfirmTokenDialog'),
        message: this.$t('messageConfirmTokenRemoval'),
        confirmText: this.$t('buttonRemove'),
        cancelText: this.$t('buttonCancel'),
        onConfirm: async () => {
          try {
            await ServiceAuth.expireTokens([token.key])
            this.getTokens()

            this.$buefy.snackbar.open({
              message: 'A sessão foi eliminada.',
              type: 'is-black',
              position: 'is-bottom-right',
              duration: 5000,
            })
          } catch (error) {
            console.error(error)
          }
        },
      })
    },
  },
}
</script>

<i18n>
{
  "pt": {
    "login": "Entrar",
    "profile": "Perfil",
    "helpAndSupport": "Ajuda e suporte",
    "logout": "Terminar sessão",
    "contacts":"Contactos",
    "reportingChannel": "Canal de Denúncia",
    "complaintsAndSugestions": "Elogios, Sugestões e Reclamações",
    "legalDisclaimer": "Aviso Legal",
    "dataProtection": "Proteção de Dados",
    "university": "Universidade de Coimbra",
    "by": "por",
    "edit": "Editar",
    "save": "Guardar",
    "removePhoto": "Remover fotografia",
    "displayName": "Nome de apresentação",
    "fullName": "Nome completo",
    "institutionalNumber": "Nº institucional",
    "preferences": "Preferências",
    "language": "Idioma preferêncial",
    "languageInfo": "O idioma definido será considerado para interface e notificações das várias plataforma integradas no Edifício Digital da UC.",
    "security": "Segurança",
    "activeSessions": "Sessões ativas",
    "byPlatform": "Por plataforma",
    "current": "Atual",
    "lastActive": "Última atividade",
    "logout": "Terminar sessão",
    "personalInstitutionalData": "Dados pessoais e institucionais",
    "displayNameRequired": "Insira um Nome de apresentação"
  },
  "en": {
    "login": "Login",
    "profile": "Profile",
    "helpAndSupport": "Help and support",
    "logout": "Logout",
    "contacts": "Contacts",
    "reportingChannel": "Reporting Channel",
    "complaintsAndSugestions": "Compliments, Suggestions and Complaints",
    "legalDisclaimer": "Legal Disclaimer",
    "dataProtection": "Data Protection",
    "university": "Coimbra University",
    "by": "by",
    "edit": "Edit",
    "save": "Save",
    "removePhoto": "Remove photo",
    "displayName": "Display Name",
    "fullName": "Full name",
    "institutionalNumber": "Nº institucional",
    "preferences": "Preferences",
    "language": "Preferred language",
    "languageInfo": "The defined language will be considered for interface and notifications of the various platforms integrated in the UC Digital Building.",
    "security": "Security",
    "activeSessions": "Active sessions",
    "byPlatform": "By platform",
    "current": "Current",
    "lastActive": "Last activity",
    "logout": "Logout",
    "personalInstitutionalData": "Personal and institutional data",
    "displayNameRequired": "Enter a display name"
  }
}
</i18n>
