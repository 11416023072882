var render = function render(){var _vm=this,_c=_vm._self._c;return (_vm.team)?_c('div',{staticClass:"relative overflow-visible justify-center items-center flex",class:{
    'h-12 w-12': _vm.size == 'xs',
    'h-16 w-16': _vm.size == 'sm',
    'h-24 w-24': _vm.size == 'md',
    'h-44 w-44': _vm.size == 'lg',
  },attrs:{"title":_vm.team?.name ?? ''}},[_c('TeamBackground',{staticClass:"absolute overflow-visible w-full h-full z-0",class:{
      'drop-shadow-2xl': _vm.withShadow,
    },style:({ color: _vm.team.color == null ? '#ccc' : _vm.team.color })}),(!_vm.team.logo)?_c('div',{staticClass:"bg-cover bg-center rounded-lg z-10 font-bold flex items-center justify-center overflow-hidden text-white bg-white bg-opacity-20",class:{
      'h-8 w-8 text-2xl': _vm.size == 'xs',
      'h-10 w-10 text-2xl': _vm.size == 'sm',
      'h-16 w-16 text-3xl': _vm.size == 'md',
      'h-28 w-28 text-5xl': _vm.size == 'lg',
    }},[_c('TeamImagePlaceholder',{staticClass:"h-24 w-24"})],1):_c('div',{staticClass:"bg-cover bg-center rounded-lg z-10 font-bold flex items-center justify-center overflow-hidden text-white bg-white bg-opacity-20",class:{
      'h-8 w-8 text-2xl': _vm.size == 'xs',
      'h-10 w-10 text-2xl': _vm.size == 'sm',
      'h-16 w-16 text-3xl': _vm.size == 'md',
      'h-28 w-28 text-5xl': _vm.size == 'lg',
    },style:({
      backgroundImage: 'url(' + _vm.getTeamLogoUrl(_vm.team.logo) + ')',
    })})],1):_vm._e()
}
var staticRenderFns = []

export { render, staticRenderFns }