<template>
  <div
    :class="[
      {
        'text-gray-400': muted,
      },
      style,
    ]"
  >
    <slot />
  </div>
</template>

<script>
export default {
  name: 'FwHeading',
  props: {
    size: {
      type: String,
      default: 'lg',
    },
    muted: {
      type: Boolean,
    },
  },

  computed: {
    style() {
      const sizesMap = {
        h1: 'font-bold text-3xl',
        h2: 'font-bold text-2xl',
        h3: 'font-semibold text-xl',
        h4: 'font-semibold text-md leading-5',
        '2xl': 'font-bold text-3xl',
        xl: 'font-bold text-2xl',
        lg: 'font-semibold text-xl',
        md: 'font-semibold text-md leading-5',
        sm: 'font-medium text-sm leading-5',
        label: 'font-semibold text-sm text-gray-500',
      }
      return sizesMap[this.size]
    },
  },
}
</script>
