import Vue from 'vue'
import VueI18n from 'vue-i18n'
import axios from 'axios'
import ShortKey from 'vue-shortkey'
import VueScrollactive from 'vue-scrollactive'
import VClamp from 'vue-clamp'
import VueClipboard from 'vue-clipboard2'
import VueNativeSock from 'vue-native-websocket'
import VueNativeNotification from 'vue-native-notification'
import JsonViewer from 'vue-json-viewer'
import Vue2Filters from 'vue2-filters'
import App from '@/App.vue'
import messages from '@/i18n'
import router from '@/router'
import store from '@/store'
import SvgIcon from 'vue-svgicon'
import Buefy from 'buefy'
import Vuelidate from 'vuelidate'
import Nprogress from 'nprogress'
import device from '@/fw-modules/fw-core-vue/utilities/device'

import FwLayout from '@/fw-modules/fw-core-vue/ui/components/layouts/FwLayout'
import FwDot from '@/fw-modules/fw-core-vue/ui/components/animation/FwDot'
import FwDotActive from '@/fw-modules/fw-core-vue/ui/components/animation/FwDotActive'
import FwLoadingBlurPanel from '@/fw-modules/fw-core-vue/ui/components/loading/FwLoadingBlurPanel'
import FwButton from '@/fw-modules/fw-core-vue/ui/components/buttons/FwButton'
import FwButtonDropdown from '@/fw-modules/fw-core-vue/ui/components/buttons/FwButtonDropdown'
import FwBlockPanelHeader from '@/fw-modules/fw-core-vue/ui/components/blocks/FwBlockPanelHeader'
import FwListItems from '@/fw-modules/fw-core-vue/ui/components/lists/FwListItems'
import FwLabel from '@/fw-modules/fw-core-vue/ui/components/text/FwLabel'
import FwHeading from '@/fw-modules/fw-core-vue/ui/components/text/FwHeading'
import FwBadge from '@/fw-modules/fw-core-vue/ui/components/text/FwBadge'
import FwTip from '@/fw-modules/fw-core-vue/ui/components/text/FwTip'
import FwTag from '@/fw-modules/fw-core-vue/ui/components/text/FwTag'
import FwMessage from '@/fw-modules/fw-core-vue/ui/components/text/FwMessage'
import FwModal from '@/fw-modules/fw-core-vue/ui/components/modals/FwModal'
import FwPanel from '@/fw-modules/fw-core-vue/ui/components/panels/FwPanel'
import FwPanelPageHeader from '@/fw-modules/fw-core-vue/ui/components/panels/FwPanelPageHeader'
import FwPanelInfo from '@/fw-modules/fw-core-vue/ui/components/panels/FwPanelInfo'
import FwCard from '@/fw-modules/fw-core-vue/ui/components/cards/FwCard'
import FwCardThumb from '@/fw-modules/fw-core-vue/ui/components/cards/FwCardThumb'
import FwAvatar from '@/fw-modules/fw-core-vue/ui/components/FwAvatar'
import FwEditor from '@/fw-modules/fw-core-vue/ui/components/FwEditor'
import FwContentLoader from '@/fw-modules/fw-core-vue/ui/components/loading/FwContentLoader'

import '@/fw-modules/fw-core-vue/filters'
import '@/fw-modules/fw-core-vue/ui/icons'

import FwEnvConfig from './config'

import '@fontsource/inter/latin.css'

// FW Core Components
Vue.component('FwLayout', FwLayout)
Vue.component('FwHeading', FwHeading)
Vue.component('FwMessage', FwMessage)
Vue.component('FwLoadingBlurPanel', FwLoadingBlurPanel)
Vue.component('FwButton', FwButton)
Vue.component('FwButtonDropdown', FwButtonDropdown)
Vue.component('FwDot', FwDot)
Vue.component('FwDotActive', FwDotActive)
Vue.component('FwLabel', FwLabel)
Vue.component('FwBlockPanelHeader', FwBlockPanelHeader)
Vue.component('FwListItems', FwListItems)
Vue.component('FwPanel', FwPanel)
Vue.component('FwPanelPageHeader', FwPanelPageHeader)
Vue.component('FwBadge', FwBadge)
Vue.component('FwTag', FwTag)
Vue.component('FwCard', FwCard)
Vue.component('FwCardThumb', FwCardThumb)
Vue.component('FwAvatar', FwAvatar)
Vue.component('FwEditor', FwEditor)
Vue.component('FwModal', FwModal)
Vue.component('FwTip', FwTip)
Vue.component('FwPanelInfo', FwPanelInfo)
Vue.component('FwContentLoader', FwContentLoader)
Vue.component('VClamp', VClamp)

Vue.use(Buefy, {
  defaultIconComponent: 'faicon',
  defaultIconPack: 'fas',
})
Vue.use(VueI18n)
Vue.use(SvgIcon, { tagName: 'icon' })
Vue.use(Vuelidate)
Vue.use(Nprogress)
Vue.use(JsonViewer)
Vue.use(VueScrollactive)
Vue.use(VueClipboard)
Vue.use(Vue2Filters, {
  capitalize: {
    onlyFirstLetter: false,
  },
  number: {
    format: '0',
    thousandsSeparator: '.',
    decimalSeparator: ',',
  },
  bytes: {
    decimalDigits: 2,
  },
  percent: {
    decimalDigits: 2,
    multiplier: 100,
    decimalSeparator: ',',
  },
  currency: {
    symbol: '€',
    decimalDigits: 2,
    thousandsSeparator: '.',
    decimalSeparator: ',',
    symbolOnLeft: false,
    spaceBetweenAmountAndSymbol: false,
    showPlusSign: false,
  },
  pluralize: {
    includeNumber: false,
  },
  ordinal: {
    includeNumber: false,
  },
})
Vue.use(ShortKey)
Vue.use(VueNativeSock, FwEnvConfig.wsUrl, {
  store: store,
  format: 'json',
  reconnection: true, // (Boolean) whether to reconnect automatically (false)
  reconnectionAttempts: Infinity, // (Number) number of reconnection attempts before giving up (Infinity),
  reconnectionDelay: 5000, // (Number) how long to initially wait before attempting a new (1000)
})
Vue.use(VueNativeNotification, {
  // Automatic permission request before
  // showing notification (default: true)
  requestOnNotify: true,
})

Nprogress.configure({ showSpinner: false, minimum: 0.1 })

Vue.config.productionTip = false
Vue.config.performance = !process.env.VUE_APP_IS_PRODUCTION || false

let language = localStorage.getItem('settings.language')
if (!language) store.dispatch('setLanguage', 'pt')
const i18n = new VueI18n({
  locale: language || 'pt',
  fallbackLocale: 'pt',
  messages,
})

Vue.prototype.$http = axios
const token = localStorage.getItem('session.token')
if (token) {
  Vue.prototype.$http.defaults.headers.common['Authorization'] = token
}

Vue.prototype.$device = device

new Vue({
  router,
  store,
  i18n,
  render: h => h(App),
}).$mount('#app')
