var render = function render(){var _vm=this,_c=_vm._self._c;return _c('button',{staticClass:"relative flex-shrink-0",class:[
    _vm.style,
    {
      'cursor-not-allowed': _vm.disabled === true || _vm.loading === true,
      'opacity-50': _vm.disabled === true && _vm.loading === false,
    },
  ],attrs:{"arial-label":_vm.label,"disabled":_vm.disabled || _vm.loading}},[(_vm.checked)?_c('fw-icon-checkbox-solid',{staticClass:"w-5 h-5 bg-white rounded-full text-primary flex-shrink-0",class:{ 'absolute -top-1 -right-1': !_vm.checkBox }}):(!_vm.checked && _vm.checkBox)?_c('fw-icon-checkbox-solid',{staticClass:"w-5 h-5 bg-white rounded-full text-gray-300 flex-shrink-0"}):_vm._e(),(_vm.$slots.icon)?_c('span',{staticClass:"flex items-center",class:{ 'gap-2.5': _vm.size != 'xs', 'gap-1': _vm.size == 'xs' }},[_c('span',{staticClass:"flex-shrink-0"},[_vm._t("icon")],2),_c('span',{staticClass:"flex-1 text-left"},[_vm._t("default")],2)]):_vm._t("default"),(_vm.loading)?_c('span',{staticClass:"absolute top-0 left-0 w-full h-full flex items-center justify-center",class:[_vm.style, { 'cursor-not-allowed': _vm.disabled === true || _vm.loading === true }]},[_c('fw-icon-loading',{staticClass:"w-5 h-5"})],1):_vm._e()],2)
}
var staticRenderFns = []

export { render, staticRenderFns }