<template>
  <div class="font-sans antialiased h-screen flex flex-col" :class="{ 'bg-gray-900': dark, 'bg-gray-50': !dark }">
    <div
      v-if="!isProduction"
      class="bg-yellow-600 px-1 text-center text-xs text-white absolute top-0 left-6 font-semibold uppercase opacity-80"
      style="font-size: 0.65rem"
    >
      &#9888; Ambiente de {{ appVersion === 'staging' ? 'pré-produção' : 'desenvolvimento' }} &#9888;
    </div>
    <header
      v-if="header"
      class="h-16 flex gap-5 items-center px-4 py-2"
      style="z-index: 3"
      :class="{ 'border-b': !isBlockedPage && !dark }"
    >
      <div class="flex gap-2 items-center">
        <div>
          <button
            v-if="backToEnable"
            class="p-1 rounded-lg bg-gray-200 bg-opacity-30 text-gray-500"
            @click="checkBackToAndGo"
          >
            <fw-icon-chevron-left class="w-6 h-6" />
          </button>
        </div>
        <AppLogo
          v-if="!backToEnable || (backToEnable && isDesktop)"
          :class="{ 'cursor-pointer': backToListenEvent || backToEnable, 'text-gray-100': dark }"
          :size="extendedLogo && isDesktop ? 'lg' : 'xs'"
          @click.native="checkBackToAndGo"
        />
      </div>
      <div class="flex-1">
        <slot v-if="!isBlockedPage" name="header-nav"></slot>
      </div>
      <div class="flex items-center">
        <slot v-if="!isBlockedPage" name="header-toolbar"></slot>
        <BlockHeaderNotifications v-if="!isBlockedPage && headerSessionToolbar && isLoggedIn && !isAnonymous" />
        <BlockHeaderApps v-if="!isBlockedPage && headerSessionToolbar && isLoggedIn && !isAnonymous" />
        <div v-if="headerSessionToolbar && isLoggedIn && !isAnonymous" class="ml-4">
          <b-dropdown aria-role="list" scrollable position="is-bottom-left" max-height="300">
            <div slot="trigger" class="flex is-link items-center justify-center">
              <Avatar size="xs" :user="user" borderless />
              <fw-icon-chevron-down v-if="isDesktop" class="w-4 h-4 ml-1"></fw-icon-chevron-down>
            </div>
            <b-dropdown-item custom aria-role="menuitem">
              <div class="mt-2">
                <v-clamp class="font-bold text-base" autoresize :max-lines="2">{{
                  user.display_name || user.full_name
                }}</v-clamp>
                <v-clamp class="text-sm text-gray-500" autoresize :max-lines="1">{{ user.email }}</v-clamp>
                <div v-if="user.number" class="text-gray-500">Nº {{ user.number }}</div>
              </div>
            </b-dropdown-item>
            <b-dropdown-item v-if="headerLanguageSwitch" has-link aria-role="menuitem">
              <SideBlockLanguage class="px-2 py-1" />
            </b-dropdown-item>
            <hr class="dropdown-divider" />
            <b-dropdown-item has-link aria-role="menuitem">
              <router-link to="/account" tag="a">{{ $t('profile') }}</router-link>
              <router-link to="/notifications" tag="a">{{ $t('notifications') }}</router-link>
              <a href="https://ucframework.pt" target="_blank">{{ $t('helpAndSupport') }}</a>
              <router-link to="/logout" tag="a">{{ $t('logout') }}</router-link>
            </b-dropdown-item>
          </b-dropdown>
        </div>
        <div v-if="!isLoggedIn && $router.currentRoute.name != loginRouteName">
          <fw-button @click.native="loginClick"> {{ $t('login') }} </fw-button>
        </div>
      </div>
    </header>
    <main v-if="isBlockedPage" class="flex flex-1 h-full items-center justify-center overflow-auto">
      <div class="flex p-5 text-center max-w-sm">
        <div v-if="loading" class="flex flex-col gap-2">
          <div class="text-gray-500 flex justify-center">
            <component :is="'fw-icon-' + loadingIcon" class="h-14 w-14 fill-current text-gray-500" />
          </div>
          <div class="text-lg text-center text-opacity-60 mx-auto max-w-sm text-gray-800">
            {{ loadingTitle }}
          </div>
          <div class="text-sm font-medium text-opacity-90 animate-pulse text-primary">
            {{ $t('loading') }}
          </div>
          <div class="w-32 h-3 rounded-xl shadow-inner bg-gray-400 bg-opacity-80 mx-auto">
            <div
              class="loading-bar h-3 rounded-full bg-primary shadow-md border-t border-white border-opacity-20"
            ></div>
          </div>
        </div>
        <div v-else-if="notfound || forbidden" class="flex flex-col gap-3">
          <div class="text-gray-500 flex justify-center">
            <fw-icon-emotion-sad v-if="notfound" class="h-14 w-14" />
            <fw-icon-lock v-else-if="forbidden" class="h-14 w-14" />
          </div>
          <div class="text-2xl font-semibold text-gray-500">{{ notfound ? '404' : '403' }}</div>
          <div class="text-xl font-semibold">
            {{ notfound ? $t('notfound') : $t('forbidden') }}
          </div>
          <div class="font-semibold text-gray-500 text-sm">
            {{ notfound ? $t('notfoundMessage') : $t('forbiddenMessage') }}
          </div>
          <div class="opacity-80 hover:opacity-100">
            <fw-button size="xs" type="link-muted" @click.native="$router.back('-1')">{{
              $t('backToPreviousPage')
            }}</fw-button>
          </div>
        </div>
        <div v-else-if="!isDeviceAllowed" class="flex flex-col gap-3">
          <div class="text-gray-500 flex justify-center">
            <fw-icon-macbook class="h-14 w-14" />
          </div>
          <div class="text-2xl font-semibold text-gray-500">Desktop only</div>
          <div class="text-xl font-semibold">
            {{ $t('notAllowedDevice') }}
          </div>
          <div class="font-semibold text-gray-500 text-sm">
            {{ $t('notAllowedDeviceMessage') }}
          </div>
          <div class="opacity-80 hover:opacity-100">
            <fw-button size="xs" type="link-muted" @click.native="$router.back('-1')">{{
              $t('backToPreviousPage')
            }}</fw-button>
          </div>
        </div>
      </div>
    </main>
    <main v-else class="flex flex-1 h-full overflow-auto">
      <slot name="main">
        <div
          v-if="$slots['main-sidebar']"
          class="main-sidebar h-full hidden lg:block overflow-auto"
          :class="[
            {
              'border-r': full,
              'w-60': !mainSidebarWidth,
            },
            mainSidebarWidth,
          ]"
        >
          <slot name="main-sidebar"></slot>
        </div>
        <div class="main-content flex-1" :class="{ 'p-5 overflow-auto flex flex-col': !full, 'h-full': full }">
          <div
            :class="{
              'max-w-screen-lg mx-auto flex-1 w-full': !full && !wide && !small,
              'max-w-screen-xl mx-auto flex-1 w-full': !full && wide && !small,
              'max-w-screen-md mx-auto flex-1 w-full': !full && !wide && small,
              'h-full': full,
            }"
          >
            <slot name="main-content"></slot>
          </div>
          <footer v-if="footer && !full" class="mt-5 justify-center flex flex-col gap-1 sm:px-5 py-3 items-stretch">
            <div class="text-xs text-gray-700 mb-5 sm:text-center">
              {{ $t('needHelp') }}
              <a :href="`mailto:support@ucframework.pt`" class="font-semibold">support@ucframework.pt</a>
            </div>

            <div class="gap-1 sm:flex text-gray-500 text-xs w-full justify-center opacity-80 select-none">
              <div class="mb-0.5">
                <span class="uppercase mr-0.5 cursor-pointer" @click="betaClick"
                  >{{ betaMessage }}{{ appName }} {{ appVersion }}</span
                >
                <span class="mx-0.5"
                  >{{ $t('by') }} <a href="https://ucframework.pt" target="_blank">UC Framework</a></span
                >
              </div>
              <div class="sm:ml-0.5">{{ currentYear }} © {{ $t('university') }}</div>
            </div>

            <div
              v-if="betaMode"
              class="text-gray-500 cursor-pointer font-semibold text-center text-xs mb-0.5 select-none"
              @click="betaClick"
            >
              Versão beta ativa! 🦄😎
            </div>

            <div class="text-xs font-semibold text-gray-500 sm:text-center opacity-70">
              <a target="_blank" href="https://www.uc.pt/sobrenos/localizacao_contactos" class="pr-2 py-2">{{
                $t('contacts')
              }}</a>
              <a target="_blank" href="https://www.uc.pt/go/denuncia" class="p-2">{{ $t('reportingChannel') }}</a>
              <a target="_blank" href="https://apps.uc.pt/forms/view/melhoria" class="p-2">{{
                $t('complaintsAndSugestions')
              }}</a>
              <a target="_blank" href="https://www.uc.pt/avisolegal" class="p-2">{{ $t('legalDisclaimer') }}</a>
              <a target="_blank" href="https://www.uc.pt/protecao-de-dados" class="pl-2 py-2">{{
                $t('dataProtection')
              }}</a>
            </div>
            <div v-if="!isLoggedIn" class="sm:flex sm:items-center sm:justify-center mt-4">
              <img
                class="h-6 sm:h-8 flex-shrink-0"
                src="@/fw-modules/fw-core-vue/ui/images/sponsors/sponsors-fse-v02-low.png"
              />
            </div>
          </footer>
        </div>
        <div
          v-if="$slots['right-sidebar']"
          class="right-sidebar h-full hidden lg:block overflow-auto shrink-0 flex-none"
          :class="[
            {
              'border-l': full && !dark,
              'w-72': !rightSidebarWidth && rightSidebarExpanded,
            },
            rightSidebarWidth,
          ]"
        >
          <slot name="right-sidebar"></slot>
        </div>
      </slot>
    </main>
    <div
      v-if="!isBlockedPage && $slots.tapbar"
      class="block inset-x-0 bottom-0"
      :class="{ 'md:hidden': !tapbarOnDesktop, 'bg-gray-100': !dark, 'bg-gray-800': dark }"
    >
      <slot name="tapbar"></slot>
    </div>
    <slot v-if="!isBlockedPage" name="modals"></slot>
  </div>
</template>

<script>
import FwEnvConfig from '@/fw-modules/fw-core-vue/config'
import AppLogo from '@/components/AppLogo'
import Avatar from '@/fw-modules/fw-core-vue/ui/components/users/Avatar'
import BlockHeaderNotifications from '@/fw-modules/fw-core-vue/notifications/components/blocks/BlockHeaderNotifications'
import BlockHeaderApps from '@/fw-modules/fw-core-vue/id/components/blocks/BlockHeaderApps'
import SideBlockLanguage from '@/fw-modules/fw-core-vue/ui/components/sidebars/blocks/SideBlockLanguage'

export default {
  components: {
    AppLogo,
    Avatar,
    BlockHeaderNotifications,
    BlockHeaderApps,
    SideBlockLanguage,
  },

  props: {
    backTo: {
      type: String,
      default: null,
    },
    backToListenEvent: {
      type: Boolean,
      default: false,
    },
    backToEnable: {
      type: Boolean,
      default: true,
    },
    full: {
      type: Boolean,
      default: false,
    },
    wide: {
      type: Boolean,
      default: false,
    },
    dark: {
      type: Boolean,
      default: false,
    },
    small: {
      type: Boolean,
      default: false,
    },
    extendedLogo: {
      type: Boolean,
      default: false,
    },
    headerSessionToolbar: {
      type: Boolean,
      default: true,
    },
    headerLanguageSwitch: {
      type: Boolean,
      default: false,
    },
    header: {
      type: Boolean,
      default: true,
    },
    footer: {
      type: Boolean,
      default: false,
    },
    mobileReady: {
      type: Boolean,
      default: false,
    },
    notfound: {
      type: Boolean,
      default: false,
    },
    forbidden: {
      type: Boolean,
      default: false,
    },
    loading: {
      type: Boolean,
      default: false,
    },
    loadingTitle: {
      type: String,
      default: 'Página',
    },
    loadingIcon: {
      type: String,
      default: 'compass',
    },
    tapbarOnDesktop: {
      type: Boolean,
      default: false,
    },
    rightSidebarExpanded: {
      type: Boolean,
      default: false,
    },
    rightSidebarWidth: {
      type: String,
      default: 'w-14',
    },
    mainSidebarWidth: {
      type: String,
    },
  },

  data() {
    return {
      window: {
        width: window.innerWidth,
        height: window.innerHeight,
      },
      appVersion: process.env.VUE_APP_VERSION,
      appName: process.env.VUE_APP_KEY,
      betaMode: false,
      betaCounter: 0,
      appEnv: process.env.VUE_APP_ENV,
    }
  },

  computed: {
    betaMessage() {
      return this.betaCounter == 2
        ? '😅 Ai!  '
        : this.betaCounter == 3
        ? '😯 Ui!  '
        : this.betaCounter == 4
        ? '😱 Outch!  '
        : this.betaCounter == 5
        ? '😳 Oi?!  '
        : ''
    },
    user() {
      return this.$store.getters.getUser
    },
    language() {
      return this.$store.state.language
    },
    unread() {
      return this.$store.getters.getUnreadNotificationsCount
    },
    notifications() {
      return this.$store.getters.getUnreadNotifications
    },
    currentYear() {
      const now = new Date()
      return now.getFullYear()
    },
    loginRouteName() {
      return process.env.VUE_APP_KEY == 'ucid' ? 'home' : 'login'
    },

    // Tests
    isDebugModeActive() {
      return Boolean(localStorage.getItem('debug'))
    },
    isLoggedIn() {
      return this.$store.getters.isLoggedIn
    },
    isAnonymous() {
      return this.user && this.user.isAnonymous
    },
    isMobile() {
      return this.window.width < 640
    },
    isDesktop() {
      return window.innerWidth >= 1024
    },
    isDeviceAllowed() {
      return this.mobileReady || this.isDesktop
    },
    isBlockedPage() {
      return this.notfound || this.forbidden || this.loading || !this.isDeviceAllowed
    },
    isProduction() {
      return this.appEnv === 'production'
    },
  },

  mounted() {
    this.betaMode = localStorage.getItem('beta') == 'true'
    this.setViewHeight()
    window.addEventListener('resize', () => {
      this.setViewHeight()
    })
    window.addEventListener('offline', () => {
      this.$notification.show(
        this.$t('serverConnectionFailure'),
        {
          body: this.$t('internetConnectionError'),
        },
        {}
      )
      this.$buefy.dialog.alert({
        title: this.$t('connectionFailure'),
        message: this.$t('internetConnectionError'),
        type: 'is-dark',
        confirmText: this.$t('reload'),
        ariaRole: 'alertdialog',
        canCancel: false,
        onConfirm: () => this.$router.go(),
      })
    })
  },

  created() {
    window.addEventListener('resize', this.handleResize)
    this.handleResize()
  },

  destroyed() {
    window.removeEventListener('resize', this.handleResize)
  },

  methods: {
    betaClick() {
      this.betaCounter++
      if (this.betaCounter >= 6) {
        this.betaMode = !this.betaMode
        if (this.betaMode) {
          localStorage.setItem('beta', 'true')
          location.reload()
        } else {
          localStorage.removeItem('beta')
        }
        this.betaCounter = 0
      }
    },
    setViewHeight: function() {
      let vh = window.innerHeight * 0.01
      document.documentElement.style.setProperty('--vh', `${vh}px`)
    },
    handleResize() {
      this.window.width = window.innerWidth
      this.window.height = window.innerHeight
    },
    getBackTo() {
      if (this.backTo == '-1') this.$router.back()
      if (this.backTo == 'emit') this.$emit('go-back-to')
      else this.$router.push(this.backTo)
    },
    checkBackToAndGo() {
      if (this.backToListenEvent) {
        this.$emit('back')
      }
      if (this.backToEnable) {
        if (this.backTo) this.getBackTo()
        else this.$router.push('/')
      }
    },
    goToNotification(key) {
      const route = this.$router.currentRoute
      if (!route || route.name != 'notification' || route.params.key != key) {
        this.$router.push({ name: 'notification', params: { key: key } })
      }
    },
    loginClick() {
      if (FwEnvConfig.appsUCIdSSO.includes(process.env.VUE_APP_KEY) && FwEnvConfig.appUrlUCId) {
        window.location.href = `${FwEnvConfig.appUrlUCId}/${process.env.VUE_APP_KEY}`
      } else {
        this.$router.push({ name: this.loginRouteName })
      }
    },
  },
}
</script>

<style lang="scss" scoped>
.loading-bar {
  width: 30%;
  animation-name: loading-bar-anim;
  animation-duration: 3s;
  animation-delay: 250ms;
  animation-fill-mode: forwards;
}

@keyframes loading-bar-anim {
  from {
    width: 30%;
  }
  to {
    width: 100%;
  }
}
</style>

<i18n>
{
  "pt": {
    "login": "Entrar",
    "profile": "Perfil",
    "helpAndSupport": "Ajuda e suporte",
    "logout": "Terminar sessão",
    "contacts":"Contactos",
    "reportingChannel": "Canal de Denúncia",
    "complaintsAndSugestions": "Elogios, Sugestões e Reclamações",
    "legalDisclaimer": "Aviso Legal",
    "dataProtection": "Proteção de Dados",
    "university": "Universidade de Coimbra",
    "by": "por",
    "notfound": "Página não encontrada",
    "notfoundMessage": "A página ou recurso solicitado não foi encontrado.",
    "backToPreviousPage": "voltar à página anterior",
    "forbidden": "Forbidden",
    "forbiddenMessage": "You don't have permission to access this page.",
    "notAllowedDevice": "Página não disponível para dispositivos móveis.",
    "notAllowedDeviceMessage": "Por favor, utilize um tablet ou computador para aceder a esta página ou recurso.",
    "notifications": "Notificações",
    "loading": "A carregar...",
    "needHelp": "Precisa de ajuda técnica? Envie-nos uma mensagem para",
    "serverConnectionFailure": "Ocorreu uma falha de ligação ao servidor",
    "internetConnectionError": "A sua ligação à internet não parece estar a funcionar. Por favor, verifique a sua ligação e tente novamente.",
    "connectionFailure": "Falha na ligação.",
    "reload": "Recarregar"
  },
  "en": {
    "login": "Login",
    "profile": "Profile",
    "helpAndSupport": "Help and support",
    "logout": "Logout",
    "contacts": "Contacts",
    "reportingChannel": "Reporting Channel",
    "complaintsAndSugestions": "Compliments, Suggestions and Complaints",
    "legalDisclaimer": "Legal Disclaimer",
    "dataProtection": "Data Protection",
    "university": "Coimbra University",
    "by": "by",
    "notfound": "Page not found",
    "notfoundMessage": "The page or resource cannot be found.",
    "backToPreviousPage": "back to previous page",
    "forbidden": "Forbidden",
    "forbiddenMessage": "You don't have permission to access this page.",
    "notAllowedDevice": "Page or resource not allowed in this device.",
    "notAllowedDeviceMessage": "Please, use a tablet or computer to access this page or resource.",
    "notifications": "Notifications",
    "loading": "Loading...",
    "needHelp": "Need technical help? Send us a message to",
    "serverConnectionFailure": "There has been a server connection failure",
    "internetConnectionError": "Your internet connection doesn't seem to be working. Please check your connection and try again.",
    "reload": "Reload"
  }
}
</i18n>
