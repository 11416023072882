import utils from '../utilities/utils'
import config from '../config'

export default {
  state: {
    currentSessionKey: utils.randomLowerString(6),
    language: localStorage.getItem('settings.language') || 'pt',
    locales: null,
    session: {
      error: false,
      errorType: false, // maxLoginAttemptsReached, InternalServerError
      errorDescription: '',
      token: localStorage.getItem('token') || '',
      user: {},
      userPhoto: null,
      appsLoaded: false,
      apps: [],
      unreadNotificationsLoaded: false,
      unreadNotifications: [],
      unreadNotificationsCount: 0,
      activeChatKey: null,
      unreadChatMessagesLoaded: false,
      unreadChatMessages: [],
      unreadChatMessagesCount: 0,
      unreadExamWsMessages: [],
      activityLogs: [],
      context: null,
    },
    isDownloading: 0,
    api: {
      isActive: true,
      ignore401: false,
    },
    socket: {
      connectionId: null,
    },
    connectedUsers: {},
    formUpdates: [], //updated when receiving a form update from the WS
    appName: process.env.VUE_APP_KEY,
    callbackReturnData: null, //to be used by callback function inside mutation
    dirtyData: false, //to be used by alert dialogs: warn the user there is not saved data before changing view
    clockTimer: null,
    now: Date.now(),
    activityTimer: null,
    lastRoutePath: null,
    allowExternalAccountsRegister: config.allowExternalAccountsRegister,
    allowExternalAccountsLogin: config.allowExternalAccountsLogin,
  },
}
