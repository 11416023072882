<template>
  <div
    v-if="team"
    class="relative overflow-visible justify-center items-center flex"
    :class="{
      'h-12 w-12': size == 'xs',
      'h-16 w-16': size == 'sm',
      'h-24 w-24': size == 'md',
      'h-44 w-44': size == 'lg',
    }"
    :title="team?.name ?? ''"
  >
    <TeamBackground
      class="absolute overflow-visible w-full h-full z-0"
      :class="{
        'drop-shadow-2xl': withShadow,
      }"
      :style="{ color: team.color == null ? '#ccc' : team.color }"
    />
    <div
      v-if="!team.logo"
      class="bg-cover bg-center rounded-lg z-10 font-bold flex items-center justify-center overflow-hidden text-white bg-white bg-opacity-20"
      :class="{
        'h-8 w-8 text-2xl': size == 'xs',
        'h-10 w-10 text-2xl': size == 'sm',
        'h-16 w-16 text-3xl': size == 'md',
        'h-28 w-28 text-5xl': size == 'lg',
      }"
    >
      <TeamImagePlaceholder class="h-24 w-24" />
    </div>
    <div
      v-else
      :style="{
        backgroundImage: 'url(' + getTeamLogoUrl(team.logo) + ')',
      }"
      class="bg-cover bg-center rounded-lg z-10 font-bold flex items-center justify-center overflow-hidden text-white bg-white bg-opacity-20"
      :class="{
        'h-8 w-8 text-2xl': size == 'xs',
        'h-10 w-10 text-2xl': size == 'sm',
        'h-16 w-16 text-3xl': size == 'md',
        'h-28 w-28 text-5xl': size == 'lg',
      }"
    ></div>
  </div>
</template>

<script>
import ServiceStorage from '@/fw-modules/fw-core-vue/storage/services/ServiceStorage'
import TeamBackground from '@/components/icons/TeamBackground'
import TeamImagePlaceholder from '@/components/icons/TeamImagePlaceholder'
export default {
  components: {
    TeamBackground,
    TeamImagePlaceholder,
  },

  props: {
    team: {
      type: Object,
      required: true,
    },
    size: {
      type: String,
      default: 'md',
    },
    withShadow: {
      type: Boolean,
      default: false,
    },
  },

  methods: {
    getTeamLogoUrl(image) {
      if (!image) return ''
      return ServiceStorage.getImageUrl(image, 'large', null)
    },
  },
}
</script>
